<template>
  <div class="ManageClients">


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>



    <CRow>
      <CCol sm="6">
        <h1> Clients du cabinet </h1>
      </CCol>
      <CCol class="text-right align-self-center" sm="6">
        <CButton
          @click="$router.push('add-new-client')"
          shape="pill"
          color="success">
            <CIcon name="cil-plus"/> Ajouter un nouveau client
        </CButton>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <CContainer>
          <CRow>
            <CCol class="text-right">
              <a v-if="!isAllClientsFilter"
                class="eclerk-link"
                href="#" @click="filterAllClients">
                Voir <strong>tous</strong> les clients
              </a>
              <a v-if="!isAllOwnClientsFilter"
                class="ml-2 link-without-decoration"
                href="#" @click="filterAllOwnClients">
                Voir uniquement <strong>vos</strong> clients
              </a>

            </CCol>
          </CRow>
          <CRow class="mt-3">
            <CCol lg="4" class="align-self-center">
              <CForm v-on:submit.prevent="searchClientByRef()">
                <CInput
                  type="text" maxlength="250"
                  v-model.trim.lazy="searchClientRef"
                  @input="$v.searchClientRef.$touch()"
                  :isValid="$v.searchClientRef.$dirty ? !$v.searchClientRef.$error : null"
                  placeholder="Rechercher par référence client (C-XXXX)">
                  <template #append>
                    <CButton
                      type="submit"
                      color="outline-dark"
                      :disabled="$v.searchClientRef.$invalid">
                      <CIcon name="cil-magnifying-glass"/>
                    </CButton>
                  </template>
                </CInput>
              </CForm>
            </CCol>
            <CCol lg="4" class="align-self-center">
              <CForm v-on:submit.prevent="searchClientByName()">
                <CInput
                  type="text" maxlength="250"
                  v-model.trim.lazy="searchClientName"
                  @input="$v.searchClientName.$touch()"
                  :state="$v.searchClientName.$dirty ? !$v.searchClientName.$error : null"
                  placeholder="Rechercher par nom du client">
                  <template #append>
                    <CButton
                      type="submit"
                      color="outline-dark"
                      :disabled="$v.searchClientName.$invalid">
                      <CIcon name="cil-magnifying-glass"/>
                    </CButton>
                  </template>
                </CInput>
              </CForm>
            </CCol>
            <CCol lg="4" class="align-self-center">
              <CForm v-on:submit.prevent="searchClientsByMissionType(true)">
                <CSelect
                  :value.sync="searchMissionType"
                  :options="missionsSelectOptions"
                  @input="$v.searchMissionType.$touch()"
                  custom>
                  <template #append>
                    <CButton
                      type="submit"
                      color="outline-dark"
                      :disabled="$v.searchMissionType.$invalid">
                      <CIcon name="cil-magnifying-glass"/>
                    </CButton>
                  </template>
                </CSelect>
              </CForm>
            </CCol>
          </CRow>

        </CContainer>
      </CCardBody>
    </CCard>

    <CCard class="mt-3">

      <CCardBody>
        <CRow v-if="userGroupContains(['MANAGER']) && clientsRender.length > 0">
          <CCol class="text-right">
            <exportTableDataComponent
              v-bind:data="clientsRender"
              v-bind:fields="clientsFileRenderFields"
              name="Eclerk - Vos clients"
              label="Exporter vos clients"
            />

          </CCol>
        </CRow>



        <CDataTable
          class="cursor-table mt-1"
          :items="clientsRender"
          :fields="clientsRenderFields"
          @row-clicked="goToClientPage"
          :noItemsView='{ noResults: "Aucun résultat", noItems: "Aucun résultat" }'
        >
        <template #is_firm="{item}">
          <td>
            <CIcon v-if="item.is_firm === true" name="cil-building"/>
            <CIcon v-else name="cil-user"/>
          </td>
        </template>

        <template #email="{item}">
          <td v-if="item.email">
            {{item.email}}
          </td>
          <td v-else>
            <em>Non renseigné</em>
          </td>
        </template>

        <template #telephone="{item}">
          <td v-if="item.telephone">
            +{{returnPhoneDial(item.phone_country_code)}} {{item.telephone}}
          </td>
          <td v-else>
            <em>Non renseigné</em>
          </td>
        </template>
        </CDataTable>

        <CContainer>
          <CRow>
            <CCol class="text-center">
              <CButton
                v-if="getAllClientsUrl && isAllClientsFilter"
                @click="getAllClients(false)"
                class="px-4"
                shape="pill"
                block
                color="outline-dark">
                Voir plus
              </CButton>

              <CButton
                v-if="getAllClientsByMissionTypeUrl && isMissionTypeClientsFilter"
                @click="searchClientsByMissionType(false)"
                class="px-4"
                shape="pill"
                block
                color="outline-dark">
                Voir plus
              </CButton>

              <CButton
                v-if="getAllOwnClientsUrl && isAllOwnClientsFilter"
                @click="getAllOwnClients(false)"
                class="px-4"
                shape="pill"
                block
                color="outline-dark">
                Voir plus
              </CButton>

            </CCol>
          </CRow>
        </CContainer>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { apiBaseUrl } from '@/variables/localVariables'

import exportTableDataComponent from '@/components/exportTableDataComponent'

import { APIUserConnected } from '@/api/APIUserConnected'
import { validationMixin } from 'vuelidate'
import renderMixins from '@/mixins/renderMixins'
import userMixins from '@/mixins/userMixins'
import { required, minLength, maxLength, integer } from 'vuelidate/lib/validators'

import { isClientRef } from '@/validators/validators'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()

export default {
  name: 'ManageClients',
  components: {
    exportTableDataComponent,
    Loading
  },
  mixins: [
    validationMixin,
    renderMixins,
    userMixins
  ],
  data: function () {
    return {



      // --------- MISSION TYPE -----------
      isMissionTypesLoading: false,
      missionTypes: [],
      missionsSelectOptions: [{label: '', value: ''}],

      // ------ Client ---
      isClientLoading: false,
      clients: [],
      clientsRender: [],
      clientsRenderFields: [
        { key: "is_firm", label: "", tdClass: 'ui-helper-center', sortable: true},
        { key: "nom", label: "Nom du client", tdClass: 'ui-helper-center', sortable: true},
        { key: "email", label: "Email", tdClass: 'ui-helper-center'},
        { key: "telephone", label: "Numéro de téléphone", tdClass: 'ui-helper-center'},
        { key: "collaborateur", label: "Collaborateur associé", tdClass: 'ui-helper-center'},
      ],

      clientsFileRenderFields: {
        'Siren': 'siren',
        'Nom': 'nom',
        'Email': 'email',
        'Téléphone': 'telephone',
        'Adresse': 'adresse',
        'Collaborateur associé': 'collaborateur'
      },

      clientIsFirmArrayForm: [
        { label: 'Une personne morale', value: true },
        { label: "Une personne physique", value: false },
      ],

      isAllClientsFilter: false,
      baseGetAllClientsUrl: apiBaseUrl + `/all-clients`,
      getAllClientsUrl: apiBaseUrl + `/all-clients`,

      isAllOwnClientsFilter: true,
      baseGetAllOwnClientsUrl: apiBaseUrl + `/all-own-clients`,
      getAllOwnClientsUrl: apiBaseUrl + `/all-own-clients`,

      // SEARCH BY NAME
      searchClientName: '',

      // SEARCH BY MISSION TYPE
      searchMissionType: '',
      isMissionTypeClientsFilter: true,
      baseGetAllClientsByMissionTypeUrl: apiBaseUrl + `/all-clients-by-mission-type/`,
      getAllClientsByMissionTypeUrl: apiBaseUrl + `/all-clients-by-mission-type/`,

      // SEARCH BY REF
      searchClientRef: ''

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isClientLoading || this.isMissionTypesLoading) {
        return true
      }
      return false
    },
  },
  created: function() {
    this.getAllOwnClients()
    this.getAllMissionTypes()
  },

  validations: {
    searchClientName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(250),
    },
    searchMissionType: {
      required,
      integer
    },
    searchClientRef: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(250),
      isClientRef(value) {
        return isClientRef(value)
      }
    },

  },

  watch: {
    missionTypes: function (newMissionTypes) {
      if (newMissionTypes.length == 0) {
        this.missionsSelectOptions = [{'label':'Rechercher par type de mission', 'value': ''}]
      }
      else {
        var final_array = [{'label':'Rechercher par type de mission', 'value': ''}]
        for (var i = 0; i < newMissionTypes.length; i++) {
          final_array.push(
            {
              'label': newMissionTypes[i].name,
              'value': newMissionTypes[i].id,
            }
          )
        }
        this.missionsSelectOptions = final_array
      }
    },
    clients: function (newClients) {
      if (newClients.length == 0) {
        this.clientsRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newClients.length; i++) {
          final_array.push(
            {
              'id': newClients[i].id,
              'is_firm': newClients[i].is_firm,
              'siren': newClients[i].siren,
              'nom': newClients[i].name,
              'email': newClients[i].email,
              'phone_country_code': newClients[i].phone_country_code,
              'telephone': newClients[i].phone_number,
              'adresse': newClients[i].address,
              'collaborateur': newClients[i].collaborateur.full_name,
            }
          )
        }
        this.clientsRender = final_array
      }
    },

  },
  methods: {

    // -------- GLOBAL--------
    getAllClients (isReset) {
      this.isClientLoading = true
      if (isReset) {
        this.clients = []
        this.getAllClientsUrl = this.baseGetAllClientsUrl
      }
      apiUserConnected.getAllClients(this.token, this.getAllClientsUrl)
      .then((result) => {
        this.clients = this.clients.concat(result.data.results)
        this.getAllClientsUrl = result.data.next
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllClientsFilter = true
        this.isAllOwnClientsFilter = false
        this.isMissionTypeClientsFilter = false
        this.isClientLoading = false
      })
    },

    getAllOwnClients (isReset) {
      this.isClientLoading = true
      if (isReset) {
        this.clients = []
        this.getAllOwnClientsUrl = this.baseGetAllOwnClientsUrl
      }
      apiUserConnected.getAllOwnClients(this.token, this.getAllOwnClientsUrl)
      .then((result) => {
        this.clients = this.clients.concat(result.data.results)
        this.getAllOwnClientsUrl = result.data.next
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllClientsFilter = false
        this.isAllOwnClientsFilter = true
        this.isMissionTypeClientsFilter = false
        this.isClientLoading = false
      })
    },

    getAllMissionTypes () {
      this.isMissionTypesLoading = true
      apiUserConnected.getAllMissionTypes(this.token)
      .then((result) => {
        this.missionTypes = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isMissionTypesLoading = false
      })
    },

    searchClientByName () {
      this.isClientLoading = true
      this.searchMissionType = ''
      this.searchClientRef = ''
      this.clients = []
      apiUserConnected.searchClientByName(this.token, this.searchClientName)
      .then((result) => {
        this.clients = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllClientsFilter = false
        this.isAllOwnClientsFilter = false
        this.isMissionTypeClientsFilter = false
        this.isClientLoading = false
      })
    },

    searchClientByRef () {
      this.isClientLoading = true
      this.searchMissionType = ''
      this.searchClientName = ''
      this.clients = []
      apiUserConnected.searchClientByRef(this.token, this.searchClientRef)
      .then((result) => {
        this.clients = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllClientsFilter = false
        this.isAllOwnClientsFilter = false
        this.isMissionTypeClientsFilter = false
        this.isClientLoading = false
      })
    },

    searchClientsByMissionType (isReset) {
      this.isClientLoading = true
      this.searchClientName = ''
      this.searchClientRef = ''
      if (isReset) {
        this.clients = []
        this.getAllClientsByMissionTypeUrl = this.baseGetAllClientsByMissionTypeUrl + this.searchMissionType
      }
      apiUserConnected.getAllClientsByMissionType(this.token, this.getAllClientsByMissionTypeUrl)
      .then((result) => {
        this.clients = this.clients.concat(result.data.results)
        this.getAllClientsByMissionTypeUrl = result.data.next
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllClientsFilter = false
        this.isAllOwnClientsFilter = false
        this.isMissionTypeClientsFilter = true
        this.isClientLoading = false
      })
    },


    filterAllClients () {
      this.getAllClientsUrl = this.baseGetAllClientsUrl
      this.getAllOwnClientsUrl = this.baseGetAllOwnClientsUrl
      this.getAllClientsByMissionTypeUrl = this.baseGetAllClientsByMissionTypeUrl
      this.clients = []
      this.getAllClients(true)
    },

    filterAllOwnClients () {
      this.getAllClientsUrl = this.baseGetAllClientsUrl
      this.getAllOwnClientsUrl = this.baseGetAllOwnClientsUrl
      this.getAllClientsByMissionTypeUrl = this.baseGetAllClientsByMissionTypeUrl
      this.clients = []
      this.getAllOwnClients(true)
    },

    goToClientPage(item) {
      this.$router.push('/client-details/' + item.id)
    }
  }
}
</script>

<style>
.minWidthTd {
   white-space: nowrap;
}
.ui-helper-center {
    text-align: center;
}
.table thead th {
  vertical-align: middle !important;
}
.cursor-table tr {
  cursor: pointer;
}

</style>
